import React from 'react';
import { Link, graphql } from 'gatsby';
import { Text, Box } from 'rebass';
import TagList from '../components/TagList';

import Bio from '../components/Bio';
import Layout from '../components/Layout';
import SEO from '../components/seo';

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark;
    const siteTitle = this.props.data.site.siteMetadata.title;
    const tags = post.frontmatter.tags;
    const { previous, next } = this.props.pageContext;

    const readingTime = post.fields.readingTime.text;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={post.frontmatter.title} description={post.excerpt} />
        <Text as="h1" mb={1}>
          {post.frontmatter.title}
        </Text>
        <Text mt={0} mb={30}>
          {post.frontmatter.date} - {readingTime} <TagList tags={tags} />
        </Text>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
        <Box as="hr" my={4} />
        <Bio />

        <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          {next && (
            <li>
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            </li>
          )}
        </ul>
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date
        tags
      }
      fields {
        readingTime {
          text
        }
      }
    }
  }
`;
